export const commonEnvironment = {
  redirectUrl: undefined as string | undefined,
  sentry: undefined as string | undefined,
  keycloakCordovaAdapter: 'default',
  messageCenter: {
    counterRefreshTime: 30000,
    messageRefreshTime: 30000,
    perPage: 8,
  },
  planning: {},
  authServerRealm: 'ebl',
  authServerClient: 'kallisto-client',
  version: '0.0.0',
  updatePollingInterval: 60,
  updatePollingIntervalDelay: 10,
  workJournal: {
    refreshTime: 60000, // 60s
  },
  tabletScreenSize: 1024,
  isServiceWorkerEnabled: false,
  enableRealtimeConnection: true,
  serverTimezoneName: 'Europe/Zurich',
  notificationsPollingIntervalInMilliseconds: 1000 * 60,
  tenants: 'ebl,PE2',
  msalConfig: {
    auth: {
      clientId: '39af9877-dff4-43b2-9a55-b451bc3b1374',
      authority: 'https://login.microsoftonline.com/c19312e5-3163-4dc8-ade8-c6d4e9efb70d',
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
  },
  authScopes: ['39af9877-dff4-43b2-9a55-b451bc3b1374/.default'],
  graphApiUrl: 'https://graph.microsoft.com/v1.0/me',
};
